import { useContext } from "react";
import { List, DateInput, SelectInput, Datagrid, TextField } from "react-admin";
import { AppDataContext } from "../../common/app-data/AppDataContext";
import moment from "moment";

export const ReportsPaymentList = (props: any) => {
  const appData = useContext(AppDataContext);
  const payment = appData.payment ?? [];

  let date = new Date();
  let _dateTo = moment(date).toISOString();
  let _dateFrom = moment(_dateTo).subtract(1, "months").toISOString();

  const postFilters = [
    <DateInput label="From" source="dateFrom" alwaysOn />,
    <DateInput label="To" source="dateTo" alwaysOn />,
    <SelectInput label="Payment" source="payment" alwaysOn choices={payment} />,
  ];

  return (
    <List
      {...props}
      filters={postFilters}
      //   dateFrom: _dateFrom, dateTo: _dateTo,
      filterDefaultValues={{ dateFrom: _dateFrom, dateTo: _dateTo, payment: 1 }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
        <TextField source="personNumber" label="Peoples" />
        <TextField source="paymentDetails" label="Payment details" />
      </Datagrid>
    </List>
  );
};
