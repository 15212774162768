import { Edit, useRecordContext } from "react-admin";
import { PaymentForm } from "./form";

const PaymentEditTitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? `"${record.name}"` : ""} </span>;
};

export const PaymentEdit = () => (
  <Edit title={<PaymentEditTitle />}>
    <PaymentForm source="type" />
  </Edit>
);
