import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceInput,
  TextInput,
  usePermissions,
} from "react-admin";

const ProgramFilter = [
  <TextInput source="_q" label="Search" alwaysOn variant="outlined" />,
  <ReferenceInput source="name" label="User" reference="users" />,
];

export const ProgramList = () => {
  const permissions = usePermissions();

  return (
    <List filters={ProgramFilter}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="type.name" />
        {permissions.permissions === "Admin" ? <EditButton /> : ""}
      </Datagrid>
    </List>
  );
};
