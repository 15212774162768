import { Admin, Resource } from "react-admin";
import userIcon from "@mui/icons-material/PeopleOutlineSharp";
import programIcon from "@mui/icons-material/Dvr";
import mealsIcon from "@mui/icons-material/Restaurant";
import paymentIcon from "@mui/icons-material/Euro";
import accomodationIcon from "@mui/icons-material/KingBedOutlined";
import reportIcon2 from "@mui/icons-material/Report";
import reportIcon3 from "@mui/icons-material/Assessment";
import reportIcon4 from "@mui/icons-material/LaptopChromebookOutlined";
import contactIcon from "@mui/icons-material/ContactPhoneOutlined";
import reportIcon5 from "@mui/icons-material/HistoryToggleOffOutlined";
import { authProvider } from "./common/authProvider";
import resourceNames from "./common/resourceNames";
import { GrabDataProvider } from "./common/api/dataProvider";
import { UserCreate } from "./components/users/create";
import { UserEdit } from "./components/users/edit";
import { UserList } from "./components/users/list";
import { LoginPage } from "./components/LoginPage";
import { ProgramList } from "./components/programs/list";
import { ProgramEdit } from "./components/programs/edit";
import { ProgramCreate } from "./components/programs/create";
import { MealEdit } from "./components/meals/edit";
import { MealCreate } from "./components/meals/create";
import { MealList } from "./components/meals/list";
import { AccomodationList } from "./components/accomodations/list";
import { AccomodationEdit } from "./components/accomodations/edit";
import { AccomodationCreate } from "./components/accomodations/create";
import { ReservationList } from "./components/reservations/list";
import { ReservationCreate } from "./components/reservations/create";
import { PaymentsList } from "./components/payments/list";
import { PaymentCreate } from "./components/payments/create";
import { PaymentEdit } from "./components/payments/edit";
import { AppDataContextProvider } from "./components/app-data/AppDataContextProvider";
import { ReservationEdit } from "./components/reservations/edit";
import { ReportsList } from "./components/reports/list";
import { ReportsPaymentList } from "./components/reports/payment";
import "./App.css";
import { ContactList } from "./components/contacts/list";
import { ReportsDaily } from "./components/reports/daily";

const dataProvider = new GrabDataProvider();

const App = () => (
  <AppDataContextProvider>
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      disableTelemetry
    >
      {(role) => {
        let resources = [
          <Resource
            name={resourceNames.programs}
            list={ProgramList}
            edit={role === "Admin" ? ProgramEdit : undefined}
            create={role === "Admin" ? ProgramCreate : undefined}
            icon={programIcon}
          />,
          <Resource
            name={resourceNames.meals}
            list={MealList}
            edit={role === "Admin" ? MealEdit : undefined}
            create={role === "Admin" ? MealCreate : undefined}
            icon={mealsIcon}
          />,
          <Resource
            name={resourceNames.accommodation}
            list={AccomodationList}
            edit={role === "Admin" ? AccomodationEdit : undefined}
            create={role === "Admin" ? AccomodationCreate : undefined}
            icon={accomodationIcon}
          />,
          <Resource
            name={resourceNames.payments}
            list={PaymentsList}
            create={role === "Admin" ? PaymentCreate : undefined}
            edit={role === "Admin" ? PaymentEdit : undefined}
            icon={paymentIcon}
          />,
          <Resource
            name={resourceNames.reservations}
            list={ReservationList}
            icon={reportIcon4}
            create={
              role === "Admin" || "Receptionist" ? ReservationCreate : undefined
            }
            edit={
              role === "Admin" || "Receptionist" ? ReservationEdit : undefined
            }
          />,
          <Resource
            name={resourceNames.reportsDaily}
            list={ReportsDaily}
            icon={reportIcon5}
          />,
        ];

        if (role === "Admin") {
          resources = [
            <Resource
              name={resourceNames.users}
              list={UserList}
              edit={role === "Admin" ? UserEdit : undefined}
              create={role === "Admin" ? UserCreate : undefined}
              icon={userIcon}
            />,
            ...resources,
            <Resource
              name={resourceNames.reports}
              list={ReportsList}
              icon={reportIcon3}
            />,
            <Resource
              name={resourceNames.reportsPayment}
              list={ReportsPaymentList}
              icon={reportIcon2}
            />,
            <Resource
              name={resourceNames.contacts}
              list={ContactList}
              icon={contactIcon}
            />,
          ];
        }

        return resources;
      }}
    </Admin>
  </AppDataContextProvider>
);

export default App;
