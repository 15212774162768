import resourceNames from "../resourceNames";

export default {
  [resourceNames.users]: "user",
  [resourceNames.programs]: "program",
  [resourceNames.meals]: "food",
  [resourceNames.accommodation]: "accommodation",
  [resourceNames.payments]: "payment",
  [resourceNames.reservations]: "reservation",
  [resourceNames.contacts]: "reservation/report/contact",
  [resourceNames.reports]: "reservation/report/country",
  [resourceNames.reportsPayment]: "reservation/report/payment",
  [resourceNames.reportsDaily]: "reservation/report/daily",
};
