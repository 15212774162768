import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceInput,
  TextInput,
  usePermissions,
} from "react-admin";

const MealFilter = [
  <TextInput source="_q" label="Search" alwaysOn variant="outlined" />,
  <ReferenceInput source="food" label="Food" reference="food" />,
];

export const MealList = () => {
  const permissions = usePermissions();

  return (
    <List filters={MealFilter}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        {permissions.permissions === "Admin" ? <EditButton /> : ""}
      </Datagrid>
    </List>
  );
};
