import { SimpleForm, TextInput } from "react-admin";
import { validateType } from "../../validations/payments/formField";

export const PaymentForm = (props: any) => {
  return (
    <SimpleForm>
      <TextInput
        variant="outlined"
        source={props.source}
        validate={validateType}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
    </SimpleForm>
  );
};
