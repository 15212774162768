import Axios from "axios";
// import { AuthProvider, UserIdentity } from "react-admin";
// import * as jwt from "jsonwebtoken";
import { parseJwt } from "./util/parseJwt";

// in src/authProvider.ts
interface LoginProps {
  username: string;
  password: string;
}

interface status {
  status: number;
}

export const authProvider = {
  // called when the user attempts to log in
  login: (data: LoginProps) => {
    // return Axios.post<string>("/api/auth/login", data).then((response) => {
    return Axios.post<any>("/api/auth/login", data).then((response) => {
      localStorage.setItem("token", response.data.access_token);
    });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: status) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    // return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
    let token = localStorage.getItem("token");
    if (token) {
      let decoded = parseJwt(token);
      // console.log("decoded = ", decoded);
      // if (decoded.exp < Date.now() / 1000 + 60 * 5) {
      if (decoded.exp < new Date().getTime()) {
        return Promise.resolve();
      } else {
        return Promise.reject({});
      }
    } else {
      return Promise.reject({});
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return "";
    }

    const decoded = parseJwt(token);
    if (!decoded) {
      return "";
    }

    return decoded.role;
  },

  getIdentity: () =>
    Promise.resolve({
      id: "user",
      fullName: "User",
    }),
};
