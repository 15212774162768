// import { useContext } from "react";
import { Create, Form, SaveButton } from "react-admin";
// import { AppDataContext } from "../../common/app-data/AppDataContext";
import { ReservationForm } from "./form";

export const ReservationCreate = () => {
  // const appData = useContext(AppDataContext);

  return (
    <Create
      title="Create reservation"
      redirect="list"
      transform={(original: any) => {
        return { ...original, description: original.undefined };
      }}
    >
      <Form>
        <ReservationForm
        // name="name"
        // personNumber="personNumber"
        // food="food"
        // veganNumber="veganNumber"
        // dateFrom="dateFrom"
        // dateTo="dateTo"
        // accommodation="accommodation"
        // programs="programs"
        // payment="payment.id"
        // country="country.id"
        // contact="contact"
        // desc="desc"
        // paymentDetails="paymentDetails"
        // vegetarianNumber="vegetarianNumber"
        // description="description"
        // date="date"
        />
        <div
          style={{
            paddingLeft: "3%",
            paddingBottom: "20px",
            marginTop: "-20px",
          }}
        >
          <SaveButton
            sx={{
              minWidth: "210px",
              width: "300px",
            }}
          />
        </div>
      </Form>
    </Create>
  );
};
