// import { useContext } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceInput,
  TextInput,
  DateField,
  FunctionField,
  usePermissions,
  DateInput,
} from "react-admin";
import { ReservationDto } from "./ReservationDto";

const reservationFilter = [
  <TextInput source="_q" label="Search" alwaysOn variant="outlined" />,
  <ReferenceInput
    source="reservation"
    label="Reservation"
    reference="reservation"
  />,
  <DateInput label="From" source="dateFrom" alwaysOn />,
  <DateInput label="To" source="dateTo" alwaysOn />,
];

export const ReservationList = () => {
  const permissions = usePermissions();

  return (
    <List
      filters={reservationFilter}
      filterDefaultValues={{
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
      }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" title="Name" label="Name" />
        <TextField source="personNumber" label="Persons nbr." />
        <DateField locales="fr-FR" source="dateFrom" label="From" />
        <DateField locales="fr-FR" source="dateTo" label="To" />
        {/* <TextField source="food.name" label="Meal" /> */}
        {/* <FunctionField<ReservationDto>
          label="Meal"
          render={(record) => {
            if (!record) {
              return null;
            }
            const food = record.description.map((x) => x.foodNames).join(", ");
            return food;
          }}
        /> */}
        <TextField source="veganNumber" label="Vegan nbr." />
        <TextField source="vegetarianNumber" label="Vegetarian nbr." />
        {/* <TextField source="accommodation.name" label="Accom." /> */}
        <FunctionField<ReservationDto>
          label="Accom."
          render={(record) => {
            if (!record) {
              return null;
            }
            const acc = record.accommodations.map((x) => x.name).join(", ");
            return acc;
          }}
        />
        {/* <FunctionField<ReservationDto>
          label="Programs"
          render={(record) => {
            if (!record) {
              return null;
            }
            const programs = record.description
              .map((x) => x.programTitles)
              .join(", ");
            return programs;
          }}
        /> */}
        {/* <FunctionField<ReservationDto>
          label="Programs"
          render={(record) => {
            if (!record) {
              return null;
            }
            // const programs = record.programsToReservation
            //   .map((x) => x.program.title + " (" + x.program.type + ") ")
            //   .join(", ");
            // return programs;

            const programs = record.programs
              .map((x) => x.name + " (" + x.type + ") ")
              .join(", ");
            return programs;
          }}
        /> */}

        {/* removed */}
        {/* <TextField source="payment.type" label="Payment" /> */}
        {/* <TextField source="country.name" label="Country" /> */}
        {permissions.permissions === "Admin" || "Receptionist" ? (
          <EditButton />
        ) : (
          ""
        )}
      </Datagrid>
    </List>
  );
};
