import { Edit, SimpleForm, useRecordContext } from "react-admin";
import { ReservationForm } from "./form";

const ReservationEditTitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? `"${record.name}"` : ""} </span>;
};

export const ReservationEdit = (props: any) => {
  return (
    <Edit
      title={<ReservationEditTitle />}
      {...props}
      transform={(original: any) => {
        return { ...original, description: original.undefined };
      }}
    >
      <SimpleForm mode="onChange">
        <div>
          <ReservationForm
          // name="name"
          // personNumber="personNumber"
          // food="food.id"
          // veganNumber="veganNumber"
          // dateFrom="dateFrom"
          // dateTo="dateTo"
          // accommodation="accommodation.id"
          // programs="programs"
          // payment="payment.id"
          // country="country.id"
          //removed
          // name="name"
          // personNumber="personNumber"
          // veganNumber="veganNumber"
          // dateFrom="dateFrom"
          // dateTo="dateTo"
          // accommodation="accommodation"
          // payment="payment.id"
          // country="country.id"
          // contact="contact"
          // desc="desc"
          // paymentDetails="paymentDetails"
          // vegetarianNumber="vegetarianNumber"
          // description="description"
          // date="description.date"
          // food="description.foodIds"
          // programs="description.programIds"
          />
        </div>
      </SimpleForm>
    </Edit>
  );
};
