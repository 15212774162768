import { SelectInput, SimpleForm, TextInput } from "react-admin";
import {
  requiredField,
  validateDesc,
  validateTitle,
} from "../../validations/programs/formFields";
import { timeOptions } from "../../common/timeOptions";

export const ProgramForm = (props: any) => {
  return (
    <SimpleForm>
      <TextInput
        variant="outlined"
        source={props.title}
        validate={validateTitle}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
      <TextInput
        variant="outlined"
        source={props.desc}
        validate={validateDesc}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
      <SelectInput
        source={props.selectInput}
        label="Time"
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
        choices={timeOptions}
        validate={requiredField}
      />
    </SimpleForm>
  );
};
