import { ArrayInput } from "react-admin";
import { AppDataItem } from "../../common/app-data/AppData";
import { DescriptionDay } from "./DescriptionDay";

interface Props {
  formData: any;
  programs: AppDataItem[];
  food: AppDataItem[];
  source: string;
}

export const DescriptionInput = ({
  formData,
  programs,
  food,
  source,
}: Props) => {
  const { dateFrom, dateTo, description } = formData;

  return (
    <ArrayInput
      className="daily-programs"
      source={source}
      label="Daily programs and food"
    >
      <DescriptionDay
        programs={programs}
        food={food}
        dateFrom={dateFrom}
        dateTo={dateTo}
        description={description}
      />
    </ArrayInput>
  );
};
