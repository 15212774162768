import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  TextInput,
} from "react-admin";

const ContactFilter = [
  <TextInput source="_q" label="Search" alwaysOn variant="outlined" />,
  <ReferenceInput source="contact" label="Food" reference="contact" />,
];

export const ContactList = () => {
  return (
    <List filters={ContactFilter}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="contact" />
      </Datagrid>
    </List>
  );
};
