import { Edit, useRecordContext } from "react-admin";
import { Form } from "./form";

const UserEditTtitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? `"${record.name}"` : ""} </span>;
};

export const UserEdit = () => {
  return (
    <Edit title={<UserEditTtitle />}>
      <Form
        name="name"
        username="username"
        password="password"
        email="email"
        role="role.id"
      />
    </Edit>
  );
};
