import { SimpleForm, TextInput } from "react-admin";
import { validateName } from "../../validations/accomodations/formFields";

export const MealForm = (props: any) => {
  return (
    <SimpleForm>
      <TextInput
        variant="outlined"
        source={props.source}
        validate={validateName}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
    </SimpleForm>
  );
};
