import { useContext } from "react";
import {
  List,
  DateInput,
  Datagrid,
  TextField,
  SelectArrayInput,
} from "react-admin";
import { AppDataContext } from "../../common/app-data/AppDataContext";
import moment from "moment";

export const ReportsList = (props: any) => {
  const appData = useContext(AppDataContext);
  const program = appData.program ?? [];

  let date = new Date();
  let _dateTo = moment(date).toISOString();
  let _dateFrom = moment(_dateTo).subtract(1, "months").toISOString();

  const postFilters = [
    <DateInput label="From" source="dateFrom" alwaysOn />,
    <DateInput label="To" source="dateTo" alwaysOn />,
    // <SelectInput label="Program" source="program" alwaysOn choices={program} />,
    <SelectArrayInput
      alwaysOn
      label="Program"
      source="program"
      sx={{
        minWidth: "210px",
        marginLeft: "3%",
        marginRight: "3%",
        width: "300px",
      }}
      choices={program}
    />,
  ];

  return (
    <List
      {...props}
      filters={postFilters}
      // dateFrom: _dateFrom, dateTo: _dateTo,
      filterDefaultValues={{
        dateFrom: _dateFrom,
        dateTo: _dateTo,
        program: [1],
      }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="Country" />
        <TextField source="totalReservation" label="Total reservations" />
        <TextField source="vegan" label="Vegans" />
        <TextField source="total" label="Peoples" />
      </Datagrid>
    </List>
  );
};
