import { Create } from "react-admin";
import { Form } from "./form";

export const UserCreate = () => {
  return (
    <Create title="Create user" redirect="list">
      <Form
        name="name"
        username="username"
        password="password"
        email="email"
        role="role.id"
      />
    </Create>
  );
};
