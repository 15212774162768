import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SumDetails, TotalDetails } from "../../common/app-data/AppData";

interface SumDetailsTableProps {
  details: TotalDetails[];
}

export default function SumTotalTable(props: SumDetailsTableProps) {
  return (
    <TableContainer component={Paper} sx={{ marginTop: "10px" }}>
      <Table
        sx={{ minWidth: 650, Top: "20px" }}
        aria-label="simple table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>Program</TableCell>
            <TableCell align="right">Total</TableCell>
            {/* <TableCell align="right">Vegan</TableCell>
            <TableCell align="right">Vegetarijanac</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.details.map((row) => (
            <TableRow key={row.id} style={{ height: "38px" }}>
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">{row.total}</TableCell>
              {/* <TableCell align="right">{row.vegan}</TableCell>
              <TableCell align="right">{row.vegetarian}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
