export default {
  users: "users",
  programs: "programs",
  meals: "food",
  accommodation: "accommodation",
  payments: "payment",
  reservations: "reservation",
  contacts: "contacts",
  reports: "reports/program",
  reportsPayment: "reports/payment",
  reportsDaily: "report/day",
};
