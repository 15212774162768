import Axios from "axios";
import { PropsWithChildren, useEffect, useState } from "react";
import { AppData } from "../../common/app-data/AppData";
import { AppDataContext } from "../../common/app-data/AppDataContext";
import CircularProgress from "@mui/material/CircularProgress";

export const AppDataContextProvider = ({ children }: PropsWithChildren) => {
  const [appData, setAppData] = useState<AppData>();

  useEffect(() => {
    Axios.get<AppData>("api/reservation/app/data", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setAppData(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  if (appData) {
    return (
      <AppDataContext.Provider value={appData}>
        {children}
      </AppDataContext.Provider>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingTop: "30%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};
