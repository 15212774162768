import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceInput,
  TextInput,
  usePermissions,
} from "react-admin";

const accFilters = [
  <TextInput source="_q" label="Search" alwaysOn variant="outlined" />,
  <ReferenceInput
    source="accomodation"
    label="Accomodation"
    reference="accomodation"
  />,
];

export const AccomodationList = () => {
  const permissions = usePermissions();

  return (
    <List filters={accFilters}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        {permissions.permissions === "Admin" ? <EditButton /> : ""}
      </Datagrid>
    </List>
  );
};
