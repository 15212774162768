import { useContext } from "react";
import {
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
  SelectArrayInput,
  FormDataConsumer,
} from "react-admin";
import { AppDataContext } from "../../common/app-data/AppDataContext";
import {
  requiredField,
  validateName,
} from "../../validations/reservations/formField";
import { RichTextInput } from "ra-input-rich-text";
import { DescriptionInput } from "./DescriptionInput";

export const ReservationForm = (props: any) => {
  const appData = useContext(AppDataContext);

  const food = appData.food ?? [];
  const accommodation = appData.accommodation ?? [];
  const country = appData.country ?? [];
  const payment = appData.payment ?? [];
  const program = appData.program ?? [];

  return (
    <>
      <TextInput
        variant="outlined"
        // source={props.name}
        source="name"
        validate={validateName}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <TextInput
        variant="outlined"
        // source={props.contact}
        source="contact"
        // validate={validateName}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <DateInput
        // source={props.dateFrom}
        source="dateFrom"
        validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <NumberInput
        variant="outlined"
        // source={props.personNumber}
        source="personNumber"
        validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      {/* <SelectArrayInput
        source={props.food}
        label="Food"
        // validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
        choices={food}
      /> */}

      <DateInput
        // source={props.dateTo}
        source="dateTo"
        validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />

      <NumberInput
        variant="outlined"
        // source={props.veganNumber}
        source="veganNumber"
        // validate={requiredField}
        defaultValue={0}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <NumberInput
        variant="outlined"
        // source={props.vegetarianNumber}
        source="vegetarianNumber"
        defaultValue={0}
        // validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />

      <SelectArrayInput
        // variant="outlined"
        // source="accommodation.id"
        // source={props.accommodation}
        source="accommodations"
        label="Acommodation"
        // validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
        choices={accommodation}
      />
      {/* <SelectArrayInput
        // source="programs"
        source={props.programs}
        // validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
        choices={program}
      /> */}

      <FormDataConsumer>
        {({ formData }) => (
          <DescriptionInput
            formData={formData}
            source="description"
            programs={program}
            food={food}
          />
        )}
      </FormDataConsumer>

      <SelectInput
        // variant="outlined"
        // source={props.payment}
        source="payment.id"
        label="Payment"
        defaultValue={0}
        // validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
        choices={payment}
      />
      <TextInput
        variant="outlined"
        // source={props.paymentDetails}
        source="paymentDetails"
        label="Payment details"
        // validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
        }}
      />
      <SelectInput
        // variant="outlined"
        // source="country.id"
        // source={props.country}
        source="country.id"
        label="Country"
        defaultValue={0}
        // validate={requiredField}
        sx={{
          minWidth: "210px",
          marginLeft: "3%",
          marginRight: "3%",
          width: "300px",
          marginBottom: "0px",
        }}
        choices={country}
      />

      <div
        style={{
          width: "94%",
          paddingLeft: "3%",
          paddingRight: "3%",
          paddingBottom: "15px",
        }}
      >
        <RichTextInput
          label="Detailed description"
          source="desc"
          sx={{ width: "93%" }}
        />
      </div>

      {/* <div
        style={{
          paddingLeft: "3%",
          paddingBottom: "20px",
          marginTop: "-20px",
        }}
      >
        <SaveButton
          sx={{
            minWidth: "210px",
            width: "300px",
          }}
        />
      </div> */}
    </>
  );
};
