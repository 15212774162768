import { Edit, useRecordContext } from "react-admin";

import { ProgramForm } from "./form";

const ProgramEditTtitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? `"${record.name}"` : ""} </span>;
};

export const ProgramEdit = () => (
  <Edit title={<ProgramEditTtitle />}>
    <ProgramForm title="title" desc="description" selectInput="type.id" />
  </Edit>
);
