import { Edit, useRecordContext } from "react-admin";
import { MealForm } from "./form";

const MealEditTitle = () => {
  const record = useRecordContext();
  return <span>Edit {record ? `"${record.name}"` : ""} </span>;
};

export const MealEdit = () => (
  <Edit title={<MealEditTitle />}>
    <MealForm source="name" />
  </Edit>
);
