import { useContext } from "react";
import { SelectInput, SimpleForm, TextInput } from "react-admin";
import { AppDataContext } from "../../common/app-data/AppDataContext";
import {
  validateEmail,
  validateName,
  validatePassword,
  validateRoles,
  validateUsername,
} from "../../validations/users/formFields";

export const Form = (props: any) => {
  const appData = useContext(AppDataContext);
  const roles = appData.roles ?? [];

  return (
    <SimpleForm>
      <TextInput
        variant="outlined"
        label="Name"
        source={props.name}
        validate={validateName}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
      <TextInput
        variant="outlined"
        label="Username"
        source={props.username}
        validate={validateUsername}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
      <TextInput
        variant="outlined"
        label="Password"
        source={props.password}
        type="password"
        validate={validatePassword}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
      <TextInput
        variant="outlined"
        label="Email"
        source={props.email}
        validate={validateEmail}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
      />
      <SelectInput
        variant="outlined"
        label="Role"
        // source="role.id"
        source={props.role}
        sx={{
          minWidth: "210px",
          width: "300px",
        }}
        validate={validateRoles}
        choices={roles}
      />
    </SimpleForm>
  );
};
