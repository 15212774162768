import Axios, { AxiosError } from "axios";
import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  //   GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
  // useCreatePath,
} from "react-admin";
import { FilterInput, InputWithIds } from "./FilterInput";
import resourceMapping from "./resourceMapping";

export class GrabDataProvider implements DataProvider {
  [key: string]: any;
  constructor() {
    Axios.defaults.baseURL = process.env["REACT_APP_BASE_URL"];
  }

  private parseFilter = (filter: any): string => {
    return Object.getOwnPropertyNames(filter).reduce((previous, current) => {
      let prefix = "?";
      if (previous.length) {
        prefix = "&";
      }
      return previous + prefix + `${current}=${filter[current]}`;
    }, "");
  };

  private getAxiosRequestConfig = () => {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
  };

  private handleAxiosError = (error: any): string => {
    if (Axios.isCancel(error)) {
      return "OPERATION_CANCELED";
    }

    const axiosError = error as AxiosError;
    if (!axiosError || !axiosError.isAxiosError) {
      console.error("An internal error has occurred", error);
      return "Desila se neočekivana greška, kontaktirajte službu za podršku!";
    }

    if (axiosError.response) {
      // The request was made and the server responded with an error status code
      console.error(
        `The server returned HTTP status code ${axiosError.response.status} - ${axiosError.response.statusText}`,
        axiosError.response.data
      );

      if (axiosError.response.status === 400) {
        return "Poziv nije pravilno poslat!";
      }

      return "Desila se neočekivana greška, kontaktirajte službu za podršku!";
    }

    if (axiosError.request) {
      // The request was made but no response has been received
      console.error(
        "The request was made but no response has been received",
        axiosError.request
      );
      //   return "Nemoguce kontaktirati server, provjerite da li ste povezani na Internet";
      return "Veza sa serverom je prekinuta, provjerite Internet konekciju!";
    }

    // We were unable to send the request at all
    console.error("Error setting up the request", axiosError.message);
    return "Desila se neočekivana greška, kontaktirajte službu za podršku!";
  };

  getList = <RecordType extends RaRecord = any>(
    resource: string,
    params: GetListParams
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    let finalObject: FilterInput = {};
    if (params.filter && Object.keys(params.filter).length !== 0) {
      finalObject = { ...finalObject, ...params.filter };
    }

    if (params.pagination) {
      finalObject._page = params.pagination.page;
      finalObject._perPage = params.pagination.perPage;
    }

    if (params.sort) {
      finalObject._sortField = params.sort.field;
      finalObject._sortOrder = params.sort.order === "DESC" ? "DESC" : "ASC";
    }

    const urlParams = this.parseFilter(finalObject);
    return Axios.get<GetListResult<RecordType>>(
      `/api/${apiName}${urlParams}`,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };

  getOne = <RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams<any>
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    return Axios.get<GetOneResult<RecordType>>(
      `/api/${apiName}/${params.id}`,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };

  getMany = <RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    const getManyInput: InputWithIds = { ids: params.ids };
    return Axios.post<GetManyResult<RecordType>>(
      `/api/${apiName}/getMany`,
      getManyInput,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };

  getManyReference = <RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ) => {
    return this.getList<RecordType>(resource, params);
  };

  update = <RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<any>
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    return Axios.patch<UpdateResult<RecordType>>(
      `/api/${apiName}/${params.id}`,
      params.data,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };

  updateMany = <RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateManyParams<any>
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    return Axios.patch<UpdateManyResult<RecordType>>(
      `/api/${apiName}/updateMany`,
      params.data,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };

  create = <RecordType extends RaRecord = any>(
    resource: string,
    params: CreateParams<any>
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    return Axios.post<CreateResult<RecordType>>(
      `/api/${apiName}`,
      params.data,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };

  delete = <RecordType extends RaRecord = any>(
    resource: string,
    params: DeleteParams<RecordType>
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    return Axios.delete<DeleteResult<RecordType>>(
      `/api/${apiName}/${params.id}`,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };

  deleteMany = <RecordType extends RaRecord = any>(
    resource: string,
    params: DeleteManyParams<RecordType>
  ) => {
    const apiName = resourceMapping[resource];
    if (!apiName) {
      throw Error(`The resource ${resource} is not mapped to any API`);
    }

    const deleteManyInput: InputWithIds = { ids: params.ids };
    return Axios.post<DeleteManyResult<RecordType>>(
      `/api/${apiName}/deleteMany`,
      deleteManyInput,
      this.getAxiosRequestConfig()
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error(this.handleAxiosError(err));
      });
  };
}
